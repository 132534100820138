export const TransformToDays = (seconds: number) => {
  const s = seconds;
  const m = s / 60;
  const h = m / 60;
  const d = h / 24;
  return Math.floor(d);
};
export const TransformToHours = (seconds: number) => {
  const s = seconds;
  const m = s / 60;
  const h = m / 60;
  return Math.floor(h);
};

export const FormatStringDate = (date: string) => {
  const dateOfRegistration = new Date(date);
  return dateOfRegistration.toLocaleDateString();
};
